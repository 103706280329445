import Glide from "@glidejs/glide";

const navTrigger = document.querySelector(".nav-burger");
const mobileNav = document.querySelector(".mobile-nav");
const mobileLinks = document.querySelectorAll(".mobile-nav__link");

if (navTrigger && mobileNav) {
  navTrigger.addEventListener("click", () => {
    navTrigger.classList.toggle("active");
    mobileNav.classList.toggle("active");
    document.body.classList.toggle("overflowed");
  });
}

if (mobileLinks) {
  mobileLinks.forEach((el) => {
    el.addEventListener("click", () => {
      document.body.classList.toggle("overflowed");
      navTrigger.classList.toggle("active");
      mobileNav.classList.toggle("active");
    });
  });
}

const plansTriger = document.querySelectorAll(".plan-xs");

plansTriger.forEach((el) => {
  el.addEventListener("click", () => {
    el.nextElementSibling.classList.toggle("active");
  });
});

new Glide(".glide").mount();

fetch('https://ipinfo.io/json')
  .then(response => response.json())
  .then(data => {
    const qClassName = data.country === 'UA' ? 'g2' : 'g1';
    document
      .querySelectorAll(`.${qClassName}`)
      .forEach(el => el.className = el.className.replace(qClassName, ''));
  });